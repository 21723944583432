import React from "react";
import Footer from "./Footer";
import About_Us from "./img/AboutPage/About-Us.PNG";
import Mission from "./img/AboutPage/Mission.PNG";
import Vision from "./img/AboutPage/Vision.PNG";
import Values from "./img/AboutPage/Values.PNG";
import GirlOnCall from "./img/AboutPage/Woman_On_Call.PNG";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
      <div className="container1">
        <Helmet>
          <title>Ayunshul Technologies</title>
          <meta
            name="description"
            content="Hi! We Are Ayunshul Technologies. Meet the company that you will enjoy working with. We build websites, web apps, mobile apps, and complex integrations for Enterprises and disruptive Start-ups."
          />
        </Helmet>
        <section className="wrapper">
          <div className="divider2">
            <h1 className="companyName">Hi! We Are Ayunshul Technologies.</h1>
            <p className="companyIntro">
              Meet the company that you will enjoy working with .........
            </p>
          </div>
        </section>
      </div>

      <div className="aboutContainer abtContainer ">
        <div className="leftsideImg">
          <img src={About_Us} alt="about" />
        </div>
        <div className="rightDesc aboutDesc">
          <p>
            We, Ayunshul Technologies Pvt Ltd, are a software development &
            consulting company focused on development & implementation of
            solutions for a wide range of industries using a variety of
            technologies and platforms. We advise clients on product strategy,
            design, and culture, then partner with them to build and launch
            technology-driven solutions to their most complex problems. We build
            websites, web apps, mobile apps, and complex integrations for
            Enterprises and disruptive Start-ups. Our software solutions are
            easy to use and are designed to scale to millions of users. Our team
            of talented developers and designers work across a diverse set of
            industries to build custom software solutions.
          </p>
        </div>
      </div>
      <div>
        <hr id="dottedLine" />
      </div>

      <div className="aboutContainer missionContainer">
        <div className="leftDesc missionDesc">
          <p>
            We enable progressive businesses to transform, scale and gain
            competitive advantage, through the expert delivery of innovative,
            tailor-made software. Our elegant, data-driven solutions help
            organizations and people around the world to perform more
            effectively and achieve better outcomes.
          </p>
        </div>
        <div className="rightsideImg">
          <img src={Mission} alt="mission" />
        </div>
      </div>
      <hr id="dottedLine" />

      <div className="aboutContainer visionContainer">
        <div className="leftsideImg ">
          <img src={Vision} alt="vision" />
        </div>
        <div className="rightDesc visionDesc">
          <p>
            To be the most recognized and trusted software development company
            based out of India by realizing the full potential of the internet
            to drive a new era of development, growth, and productivity inspired
            by pride and customers' success.
          </p>
        </div>
      </div>
      <hr id="dottedLine" />

      <div className="aboutContainer valueContainer">
        <div className="leftDesc valueDesc">
          <p>
            Collaborate with our clients to rise to challenges and celebrate
            successes. Work passionately for leaving the digital space better
            than we found it, that keeps our values alive. We believe on working
            together and winning together.
          </p>
        </div>
        <div className="rightsideImg">
          <img src={Values} alt="values" />
        </div>
      </div>

      <div className="letsTalk">
        <div className="leftImg">
          <h6>Let's Talk...</h6>
          <h1>How can we help your business grow?</h1>
          <a href="/contact" className="btn btn-outline-danger service_btn">
            Work with us
          </a>
        </div>
        <div className="rightImg workWithus">
          <img src={GirlOnCall} alt="GirlOnCall" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
