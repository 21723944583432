import React from "react";
import Footer from "./Footer";
import ContactUs from "./img/ContactPage/ContactUs.PNG";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className=" contact">
      <Helmet>
        <title>Ayunshul Technologies</title>
        <meta
          name="description"
          content="We'd love to hear from you! let us know, how can we help you grow? mail Us on contact@ayunshultechnologies.com or call on +91 9960125472 "
        />
      </Helmet>
      <div className="container2">
        <section className="wrapper">
          <div className=" divider">
            <h1>We'd love to hear from you,</h1>
            <p>
              Reach out to us for questions about features, trials, pricing, a
              demo, or anything else, we are here to help.
            </p>
          </div>
        </section>
      </div>

      <div className="conactBorder">
        <div className="commonHeader contactHeader">
          <div className="leftCommon leftContact">
            <h1>Let us know, How can we help you grow?</h1>
          </div>
          <div className="rightCommon rightContact">
            <h2>
              We become an extension of your team. Your challenges and goals
              become ours.
            </h2>
            <h6>
              Let's discuss how we can help you achieve your goals and desired
              outcomes with the right solutions.
            </h6>
          </div>
        </div>

        <div className="form">
          <div className="powr-contact-form" id="06f84b63_1618165148"></div>
          <div className="formImg">
            <img src={ContactUs} alt="contactUS" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
