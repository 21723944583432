import React from "react";
import { Call } from "@mui/icons-material";

const ServiceCard = (props) => {
  return (
    <>
      <div className="col-md-4 col-10 mx-auto">
        <div className="card">
          <img src={props.src} className="card-img-top" alt="cardImg" />
          <div className="card-body">
            <h5 className="card-title font-weight-bold mx-auto">
              {props.title}
            </h5>
            <p className="card-text">{props.details}</p>
            <h4 id="key_features">Key Features</h4>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">{props.li1}</li>
              <li className="list-group-item">{props.li2}</li>
              <li className="list-group-item">{props.li3}</li>
              <li className="list-group-item">{props.li4}</li>
              <li className="list-group-item">{props.li5}</li>
            </ul>
            <div className="KnwmoreBtn">
              <a href="contact">
                <button className="btn btn-outline-warning service_btn">
                  <Call className="callIcon" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
