import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Service from "./Service";
import Navbar from "./Navbar";
import Career from "./Career";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <Helmet>
          <title>Ayunshul Technologies</title>
          <meta
            name="description"
            content="We, Ayunshul Technologies Pvt Ltd, are a software development & consulting company focused on development & implementation of solutions for a wide range of industries using a variety of technologies and platforms."
          />
          <meta
            name="keywords"
            content="Ayunshul Technologies, Ayunshul Technologies PVT LTD, Ayunshul, Web Development,App Development,Software Development,Quality Assurance,Digital Marketing, & Web Maintenance..."
          />
        </Helmet>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/service" component={Service} />
          <Route exact path="/about" component={About} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/contact" component={Contact} />
          <Redirect to="/" />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
