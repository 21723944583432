import React from "react";
import "./Footer.jsx";
import { NavLink } from "react-router-dom";
import {
  Call,
  LocationOn,
  Email,
  LinkedIn,
  Facebook,
  WhatsApp,
  Copyright,
} from "@mui/icons-material";
import footerLogo from "./img/Ayunshul-Technologies_Logo.PNG";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer-section">
      <div className="footerContainer">
        <div className="footer-side-icon">
          <div className="mailCall">
            <div className="single-cta">
              <div className="all-cta">
                <LocationOn className="icon" fontSize="large" />
              </div>
              <div className="cta-text">
                <h4>Find us</h4>
                <span>
                  Sr. No. 42/2/78, C2-403 Dwarka Sai, Rahatani, Pune-411017
                </span>
              </div>
            </div>

            <div className="single-cta">
              <div className="all-cta">
                <Call className="icon" fontSize="large" />
              </div>
              <div className="cta-text">
                <h4>Call us</h4>
                <span>+91 9960125472</span>
              </div>
            </div>

            <div className="single-cta">
              <div className="all-cta">
                <Email className="icon" fontSize="large" />
              </div>
              <div className="cta-text">
                <h4>Mail us</h4>
                <span>contact@ayunshultechnologies.com</span>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-center">
          <div className="footer-logo">
            <a className="navbar-brand" href="/">
              <img src={footerLogo} className="img-fluid" alt="logo" />
            </a>
          </div>
          <div className="footer-text">
            <p style={{ marginTop: "1rem" }}>Your Partner In Growth</p>
          </div>

          <div className="socialIcons">
            <div>
              <NavLink to="/contact" rel="noopener noreferrer">
                <Facebook className="Sicon facebook-bg" />
              </NavLink>
            </div>
            <div>
              <NavLink to="/contact" rel="noopener noreferrer">
                <LinkedIn className="Sicon LinkedIn-bg" />
              </NavLink>
            </div>
            <div>
              <a
                href="https://api.whatsapp.com/send?phone=9960125472&text=Hello%20ChandanTech%0AI%27m%20a%20visitor%20on%20your%20website%20Need%20to%20talk%20about"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsApp className="Sicon WhatsApp-bg" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-widget">
          <div className="footer-widget-heading">
            <h3>Useful Links</h3>
          </div>
          <ul>
            <li>
              <NavLink exact to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/service">Services</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <NavLink exact to="/career">
                Career
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
              <div className="copyright-text">
                <p>
                  <span style={{ fontSize: "1em" }}>
                    <Copyright
                      style={{ fontSize: "1.4em", margin: "0 0.25em 0.2em 0" }}
                    />
                    {year} Ayunshul Technologies. All Rights Reserved.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
