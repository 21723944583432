const carouselItem = [
  {
    MainQ: "Want to grow your online presence?",
    SubQ: "Yes? We can help!",
  },
  {
    MainQ: "Can your Customer find you online ?",
    SubQ: "Reach out to us to find how we can help.",
  },

  {
    MainQ: "Not getting expected results from social presence?",
    SubQ: "No? We are here to help you.",
  },
  {
    MainQ: "Looking experties in Software & Web Development?",
    SubQ: "You are at right place.",
  },
];

const services = [
  {
    Sname: "Web Development",
    Sdesc:
      "We provide high quality web development services for marketing sites and custom web- based software application.",
  },
  {
    Sname: "App Development",
    Sdesc:
      "We provide customers with full-cycle development from idea to product launching, support and continuous delivery.",
  },
  {
    Sname: "Software Development",
    Sdesc:
      " We provide services of the development, implementation and integration of business applications in different spheres.",
  },
  {
    Sname: "Quality Assurance",
    Sdesc:
      " Quality is utmost important in every project we work with. We execute robust QA strategies/standards in all of our projects.",
  },
  {
    Sname: "Digital Marketing",
    Sdesc:
      "We increase your business by improving your current rankings by incorporating better and robust SEO practices in the projects.",
  },
  {
    Sname: "Web Maintenance",
    Sdesc:
      "We provide incomparable web maintenance solutions to increase rankings with search engines in various platforms.",
  },
];

// const industries = [
//     { Iname: "IT & Telecom", icon: "LanguageIcon" },
//     { Iname: "ECommerce", icon: "ShoppingCartOutlinedIcon" },
//     { Iname: "Healthcare", icon: "FavoriteBorderOutlinedIcon" },
//     { Iname: "Manufacturing", icon: "MenuBookOutlinedIcon" },
//     { Iname: "Media", icon: "BusinessOutlinedIcon" },
//     { Iname: "Education", icon: "TheatersOutlinedIcon" },
//     { Iname: "Mobile", icon: "MonetizationOnOutlinedIcon" },
//     { Iname: "Legal", icon: "AccountBalanceOutlinedIcon" },
//     { Iname: "Finance", icon: "MoneyOffOutlinedIcon" },
//     { Iname: "Governance", icon: "" },
//     { Iname: "Insurance", icon: "" },
//     { Iname: "Transportation", icon: "" },

// import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
// import MoneyOffOutlinedIcon from "@material-ui/icons/MoneyOffOutlined";
// import GavelOutlinedIcon from "@material-ui/icons/GavelOutlined";
// import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
// import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
// import LinearScaleOutlinedIcon from "@material-ui/icons/LinearScaleOutlined";

// ]

export { carouselItem, services };
