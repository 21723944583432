import React from "react";
import Footer from "./Footer";
import OfficeImg from "./img/CareerPage/Office_Img.PNG";
import JoinUs from "./img/CareerPage/Join_Us.PNG";

const Carrer = () => {
  return (
    <div className="career">
      <div className=" commonHeader careerHeader">
        <div className=" leftCommon leftCareer">
          <h1>Join our growing team</h1>
        </div>
        <div className=" rightCommon rightCareer">
          <h2>We're a dynamic group of growth-minded individuals.</h2>
          <h6>
            When you join the Ayunshul team, you become part of the family.
          </h6>
        </div>
      </div>
      <img
        id="officeImg"
        src={OfficeImg}
        width="100%"
        height="auto"
        alt="OfficeImg"
      />
      <div className="hiring">
        <div className="commonHeader hiringHeader">
          <div className="leftCommon leftHiring">
            <h1>Hiring talented, passionate people.</h1>
          </div>
          <div className="rightCommon rightHiring">
            <h2>
              If you have the skill sets in any of the areas below, we want to
              hear from you.
            </h2>
            <h6>
              Submit your resume and apply for one of our open positions below.
            </h6>
          </div>
        </div>
        <div className="Join_US">
          <div className="JoinUsImg">
            <img src={JoinUs} alt="JoinUs" />
          </div>
          <div className="jobBoard">
            <div className="powr-job-board" id="d090d15e_1618071729"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Carrer;
