import React from "react";
import { Link } from "react-router-dom";
import { services } from "./Data/HomeData";
import {
  Language,
  ShoppingCartOutlined,
  FavoriteBorderOutlined,
  MenuBookOutlined,
  BusinessOutlined,
  TheatersOutlined,
  MonetizationOnOutlined,
  AccountBalanceOutlined,
  MoneyOffOutlined,
  GavelOutlined,
  PhoneAndroidOutlined,
  LocalShippingOutlined,
} from "@mui/icons-material";
import Footer from "./Footer";
import HomePageVideo from "./Video/Homepage_background-Video.MP4";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="container-fluid home">
      <Helmet>
        <title>Ayunshul Technologies</title>
        <meta
          name="description"
          content="We, Ayunshul Technologies Pvt Ltd, are a software development & consulting company focused on development & implementation of solutions for a wide range of industries using a variety of technologies and platforms."
        />
      </Helmet>
      {/* Video Section */}
      <div id="videoDiv2">
        <video id="video2" loop autoPlay muted>
          <source src={HomePageVideo} type="video/mp4" />
        </video>

        <div id="videoMessage2" className="styling">
          <div className="content">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
              data-interval="3000"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <h1>Want to grow your online presence?</h1>
                  <h3>Yes? We can help!</h3>
                  <div className="talkBtn">
                    <Link to="/contact" className="btn btn-primary">
                      Let's Talk
                    </Link>
                  </div>
                </div>

                <div className="carousel-item">
                  <h1>Can your Customer find you online?</h1>
                  <h3>Reach out to us to find how we can help.</h3>
                  <div className="talkBtn">
                    <Link to="/contact" className="btn btn-primary">
                      Let's Talk
                    </Link>
                  </div>
                </div>

                <div className="carousel-item">
                  <h1>Not getting expected results from social presence?</h1>
                  <h3>No? We are here to help you.</h3>
                  <div className="talkBtn">
                    <Link to="/contact" className="btn btn-primary">
                      Let's Talk
                    </Link>
                  </div>
                </div>

                <div className="carousel-item">
                  <h1>Looking experties in Software & Web Development?</h1>
                  <h3>You are at right place.</h3>
                  <div className="talkBtn">
                    <Link to="/contact" className="btn btn-primary">
                      Let's Talk
                    </Link>
                  </div>
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* About us section */}

      <section className="aboutUs">
        <div className="container-fluid">
          <div className=" row">
            <div className="col-md-5 pt-5 pt-lg-0 order-2 order-lg-1 heading">
              <h1>Your Partner In Growth </h1>
              <h2>
                <strong>Ayunshul Technologies</strong>{" "}
              </h2>
              <div className="moreBtn">
                <Link to="/contact" className="btn btn-outline-danger">
                  Know more
                </Link>
              </div>
            </div>
            <div className="col-md-7 pt-5 pt-lg-0 order-2 order-lg-1 description">
              <p>
                We, Ayunshul Technologies Pvt Ltd, are a software development &
                consulting company focused on development & implementation of
                solutions for a wide range of industries using a variety of
                technologies and platforms. We advise clients on product
                strategy, design, and culture, then partner with them to build
                and launch technology-driven solutions to their most complex
                problems. We build websites, web apps, mobile apps, and complex
                integrations for Enterprises and disruptive Start-ups. Our
                software solutions are easy to use and are designed to scale to
                millions of users. Our team of talented developers and designers
                work across a diverse set of industries to build custom software
                solutions.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Service Summary */}
      <section className="ServiceSummary">
        <h1> We serve to reach you new heights </h1>
        <div className="flex-container">
          {services.map((service) => {
            return (
              <div className="flex-item" key={service.Sname}>
                <h1>{service.Sname}</h1>
                <div>
                  <hr id="divider" />
                </div>
                <h6>{service.Sdesc}</h6>
                <Link to="/service">know more</Link>
              </div>
            );
          })}
        </div>
      </section>

      {/* Industries */}
      <section className="industries">
        <h1> We work accross a number of Industries </h1>
        <div className="flex-container">
          <div className="item">
            <Language id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>IT & Telecom</h2>
          </div>

          <div className="item">
            <ShoppingCartOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>ECommerce</h2>
          </div>

          <div className="item">
            <FavoriteBorderOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Healthcare</h2>
          </div>

          <div className="item">
            <BusinessOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Manufacturing</h2>
          </div>

          <div className="item">
            <TheatersOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Media</h2>
          </div>

          <div className="item">
            <MenuBookOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Education</h2>
          </div>

          <div className="item">
            <PhoneAndroidOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Mobile</h2>
          </div>

          <div className="item">
            <GavelOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Legal</h2>
          </div>

          <div className="item">
            <MonetizationOnOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Finance</h2>
          </div>

          <div className="item">
            <AccountBalanceOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Governance</h2>
          </div>

          <div className="item">
            <MoneyOffOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Insurance</h2>
          </div>

          <div className="item">
            <LocalShippingOutlined id="customIcon" />
            <div className="overlay">
              <div className="text">
                <Link to="/contact">
                  <button className="btn btn-dark">get started</button>
                </Link>
              </div>
            </div>
            <h2>Transportation</h2>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
