import React from "react";
import { NavLink } from "react-router-dom";
import logoImage from "./img/Ayunshul-Technologies_Logo.PNG";

const Navbar = () => {
  return (
    <>
      <div className="container-fluid nav-bg">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <NavLink className="navbar-brand" to="/">
            <img src={logoImage} alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/home"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>

              <li className="nav-item dropdown">
                <NavLink
                  // activeClassName="menu_active"
                  className="nav-link dropdown-toggle"
                  to="/service"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <NavLink
                    className="dropdown-item"
                    to="/service"
                    id="Web development"
                  >
                    Web development
                  </NavLink>
                  <NavLink className="dropdown-item" to="/service">
                    App development
                  </NavLink>
                  <NavLink className="dropdown-item" to="/service">
                    Software development
                  </NavLink>
                  <NavLink className="dropdown-item" to="/service">
                    Quality Assurance
                  </NavLink>
                  <NavLink className="dropdown-item" to="/service">
                    Digital Marketing
                  </NavLink>
                  <NavLink className="dropdown-item" to="/service">
                    IT Staffing
                  </NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName="menu_active"
                  className="nav-link"
                  to="/about"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName="menu_active"
                  className="nav-link"
                  to="/career"
                >
                  Career
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName="menu_active"
                  className="nav-link"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
