import React from "react";
import ServiceCard from "./ServiceCard";
import ServiceData from "./ServiceData";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Service = () => {
  return (
    <div className="servicePage">
      <Helmet>
        <title>Ayunshul Technologies</title>
        <meta
          name="description"
          content="Check our services and let's help you make your goals a reality. We provide best services in Web Development,App Development,Software Development,Quality Assurance,Digital Marketing,& Web Maintenance"
        />
      </Helmet>
      <div className="commonHeader serviceHeader">
        <div className=" leftCommon leftService">
          <h1>We believe in your growth.</h1>
        </div>
        <div className=" rightCommon rightService">
          <h2>
            We tailor our solutions to fit your needs by integrating the right
            tactics, technologies and teams to drive measurable results.
          </h2>
          <h6>
            Check our services and let's help you make your goals a reality.
          </h6>
        </div>
      </div>

      <div className="container-fluid mb-5 allCards">
        <div className="row">
          <div className="col-10 mx-auto gy-4">
            <div className="row">
              {ServiceData.map((Service) => {
                return (
                  <ServiceCard
                    key={Service._id}
                    src={Service.src}
                    title={Service.title}
                    details={Service.details}
                    li1={Service.li1}
                    li2={Service.li2}
                    li3={Service.li3}
                    li4={Service.li4}
                    li5={Service.li5}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="bestServices">
        <h1>We Provide Best Services In Industry</h1>
        <a href="/contact">
          <h3>Contact Us</h3>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Service;
