import Web_Developement from "./img/ServicesImages/Web-Development.PNG";
import Software_Development from "./img/ServicesImages/Software-development.PNG";
import Quality_Assurance from "./img/ServicesImages/Quality_Assurance.PNG";
import Digital_Marketing from "./img/ServicesImages/digital_marketing.PNG";
import Website_Maintenance from "./img/ServicesImages/Website-Maintenance.PNG";
import IT_Staffing from "./img/ServicesImages/IT_Staffing.PNG";

const ServiceData = [
  {
    _id: 1,
    src: Web_Developement,
    title: "Web development",
    details:
      " We provide high quality web development services for marketing sites and custom web-based software application. We prototype, design, build and launch digital experiences for all devices. Our team utilizes the latest technologies, including REST API, various databases and full stack development to build websites that attract, engage, and convert your particular audience .",
    li1: "UI/UX & web design",
    li2: "prototype development",
    li3: "Responsive websites",
    li4: "Content migration & import",
    li5: "Payment systems integration",
  },

  {
    _id: 2,
    src: Software_Development,
    title: "Software development",
    details:
      " We provide services of the development, implementation and integration of business applications in different spheres. Our development team focuses on overall vision of necessary solutions for software product development by analyzing the customers’ business processes, difficulty of tasks, trends of the modern technology development and market conditions.",
    li1: "Deployment & integration of software",
    li2: "Software Updation & maintenance",
    li3: "Additional configurations and modules",
    li4: " Integration into mobile systems",
    li5: "Android / ios & IoT app development",
  },

  {
    _id: 3,
    src: Quality_Assurance,
    title: "Quality Assurance",
    details:
      "Quality is front and center in every project we work with. We execute robust QA strategies grounded in solid cross-domain expertise and comprehensive QA knowledge. Our approach to software testing is focused on early testing & predictable results with total accountibility. We ensure the resulting product complies with necessary quality standards and surpasses user expectations.",
    li1: "QA Consulting",
    li2: "Manual Testing",
    li3: "Automated Testing",
    li4: "AQA framework",
    li5: "QA Audit",
  },

  {
    _id: 4,
    src: Digital_Marketing,
    title: "Digital Marketing",
    details:
      "Our website optimization team analyze the current rankings and improve them by incorporating best marketing strategy into an overall SEO strategy to improve your business’ rankings in Google, Yahoo, Bing, and other major search engines. Our skilled wed design team uses both on-page and off-page search engine optimization (SEO) to bring customers who are looking for the information, services, products, or business capabilities that you offer.",
    li1: "SEO Consulting",
    li2: "Digital Audits",
    li3: "Stratagic Planning",
    li4: "SEO Execution",
    li5: "SEO Analytics",
  },

  {
    _id: 5,
    src: Website_Maintenance,
    title: "Web Maintenance",
    details:
      " We understand that maintaining a website takes time and dedication so we act as an extension of your team by understanding your business and your goals so we can make updates and recommendations to improve your website and, ultimately, your business. Our team content strategist and maintenance developers is here to provide you with an incomparable web maintenance solution to help preserve and/or increase your rankings with search engines.",
    li1: "Website Checkup",
    li2: "Technical Website Maintenance",
    li3: "Content & SEO Maintenance",
    li4: "Server Side & Pagespeed Maintenance",
    li5: "Security scans",
  },

  {
    _id: 6,
    src: IT_Staffing,
    title: "IT Staffing",
    details:
      " Our rich and varied experience in manpower consultancy enables us to assist your organization in recruiting & deputing talented individuals across designations. With diverse expertise across industries and verticals, we help you identify exactly the candidates you need. If you are looking for the best manpower, your search ends here. Join hands with us today and let us take care of your hiring woes. We deliver the solutions that drive your business forward.",
    li1: "Project Solutions",
    li2: "Project Based Hiring",
    li3: "Outplacement Services",
    li4: "Technical & Software Training",
    li5: "Recruitment Process Outsourcing (RPO)",
  },
];

export default ServiceData;
